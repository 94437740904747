<template>

  <div>
    Adding widget
  </div>

</template>

<script>
import api from '../api'
import { Server } from '../utils/config'
import { mapGetters } from 'vuex'

export default {
  name: 'Posts',
  data () {
    return {
      propertyList: [],
      widget: null
    }
  },
  computed: mapGetters(['properties', 'getAccount']),
  async mounted () {
    const userId = this.getAccount.$id
    if (!userId) {
      console.log('User id is null or no property/place_id')
    } else {
      for (const property of this.properties) {
        this.propertyList.push(property.$id)
      }
      const data = {
        name: 'Untitled widget',
        layout: 'masonry',
        propertyLogo: true,
        propertyName: true,
        postTitle: true,
        postCaption: true,
        properties: this.propertyList
      }
      const read = ['role:all']
      const write = [`user:${userId}`]
      this.widget = await api.createDocument(Server.widgetsCollectionID, data, read, write)
      this.$router.push('/widget/' + this.widget.$id)
    }
  }
}
</script>
