<template>
  <div>

    <div v-if="modal" class="fixed inset-0 bg-gray-600 bg-opacity-50 overflow-y-auto h-full w-full grid grid-cols-1 place-content-center z-20">
      <div class="relative top-0 mx-auto border w-11/12 sm:w-4/5 lg:w-1/2 shadow-lg rounded-md bg-white">
        <div class="mt-3 mb-3 relative">
          <button @click.prevent="modal = !modal" class="absolute right-0 top-0 mt-1 mr-5 text-xl opacity-50 hover:opacity-100"><i class="uil uil-multiply"></i></button>
          <h3 class="text-lg px-5 pt-1 pb-2 border-b font-medium text-gray-900">Embedding Code</h3>
          <div class="px-5 py-5 text-gray-500 overflow-scroll" style="max-height: 80vh;">
            To embed this widget on your website, add the following javascript to your website:<br/>
            <router-link :to="'/embed/' + widget.$id" class="border-b border-gray-300" target="_blank">Click here</router-link> to see a preview of the widget.
            <div class="bg-gray-100 px-3 py-1.5 rounded-md my-4">
              <code class="text-xs">
                &lt;div class="newstravel-embed" data-ref="61d71b15c9c0a0f153e4">&lt;/div><br/>
                &lt;script src="https://dashboard.news.travel/cdn/newstravel.js" async>&lt;/script>
              </code>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="flex flex-no-wrap w-100 min-h-screen">

      <div class="flex-none w-80 bg-white shadow-lg mr-6">
        <div class="fixed bottom-0 w-80 z-10">

          <div class="flex m-5 mb-0 pb-5 bg-white">
            <div class="pr-1">
              <router-link to="/widgets" class="block bg-green-800 text-white px-5 py-3 text-center">Back</router-link>
            </div>
            <div class="flex-grow">
              <button @click.prevent="handleUpdate(widget)" class="block text-white p-3 text-center w-full" :class="changes ? 'bg-green-800' : 'bg-gray-200 cursor-not-allowed'" :disabled="!changes">
                Save Changes
              </button>
            </div>
          </div>

        </div>
        <div class="fixed overflow-scroll w-80 p-5 pb-24 border" style="top: 64px; height: calc(100vh - 64px);">

          <h1 class="text-lg font-semibold">Widget Name</h1>
          <input v-model="widget.name" placeholder="Widget Name" class="border px-3 py-2 mt-2 w-full" @keyup="handleChange()">
          <a :href="'/embed/' + widget.$id" @click.prevent="modal = !modal" class="block mt-2 text-right hover:underline">view embedding code</a>

          <h1 class="mt-8 text-lg font-semibold">Layout</h1>

          <div class="radio">
            <input v-model="selectedLayout" id="collage" value="collage" type="radio" @change="handleChangeLayout('collage')">
            <label for="collage" class="radio-label">Collage</label>
          </div>

          <div class="radio">
            <input v-model="selectedLayout" id="slider" value="slider" type="radio" @change="handleChangeLayout('slider')">
            <label for="slider" class="radio-label">Slider</label>
          </div>

          <div class="radio">
            <input v-model="selectedLayout" id="centered-slider" value="centered-slider" type="radio" @change="handleChangeLayout('centered-slider')">
            <label for="centered-slider" class="radio-label">Centered slider</label>
          </div>

          <div class="radio">
            <input v-model="selectedLayout" id="gallery" value="gallery" type="radio" @change="handleChangeLayout('gallery')">
            <label for="gallery" class="radio-label">Gallery</label>
          </div>

          <div class="radio">
            <input v-model="selectedLayout" id="masonry" value="masonry" type="radio" @change="handleChangeLayout('masonry')">
            <label for="masonry" class="radio-label">Masonry</label>
          </div>

          <h1 class="mt-8 text-lg font-semibold">Format</h1>

          <div class="flex w-full">
            <div class="flex-none w-32 pt-4 pr-3">
              <label>Border Radius</label>
            </div>
            <div class="grow w-full">
              <v-select v-model="postBorderRadius" :options="borderRadius" class="select sm" :clearable="false"></v-select>
            </div>
          </div>

          <div class="flex w-full">
            <div class="flex-none w-32 pt-4 pr-3">
              <label>Border Width</label>
            </div>
            <div class="grow w-full">
              <v-select v-model="postBorderWidth" :options="borderWidth" class="select sm" :clearable="false"></v-select>
            </div>
          </div>

          <div class="flex w-full" v-if="postBorderWidth.value != 'border-0'">
            <div class="flex-none w-32 pt-4 pr-3">
              <label>Border Color</label>
            </div>
            <div class="grow w-full">
              <input v-model="widget.postBorderColor" placeholder="Border color" class="border px-3 py-2 mt-2 w-full" @keyup="handleChange()">
            </div>
          </div>

          <div class="flex w-full">
            <div class="flex-none w-32 pt-4 pr-3">
              <label>Shadow</label>
            </div>
            <div class="grow w-full">
              <v-select v-model="postShadow" :options="shadow" class="select sm" :clearable="false"></v-select>
            </div>
          </div>

          <h1 class="mt-8 text-lg font-semibold">Filters</h1>

          <div class="flex w-full">
            <div class="flex-none w-20 pt-4 pr-3">
              <label>Language</label>
            </div>
            <div class="grow w-full">
              <v-select v-model="selectedLanguage" :options="language" class="select sm"></v-select>
            </div>
          </div>

          <div class="flex w-full">
            <div class="flex-none w-20 pt-4 pr-3">
              <label>Type</label>
            </div>
            <div class="grow w-full">
              <v-select v-model="selectedType" :options="type" class="select sm"></v-select>
            </div>
          </div>

          <h1 class="mt-8 text-lg font-semibold">Posts</h1>

          <label for="propertyLogo" class="flex items-center cursor-pointer w-full my-3">
            <div class="w-full mr-3">
              Property logo
            </div>
            <div class="relative">
              <input v-model="widget.propertyLogo" id="propertyLogo" type="checkbox" class="sr-only" @change="handleChange()"/>
              <div class="w-10 h-4 bg-gray-200 rounded-full shadow-inner"></div>
              <div class="dot absolute w-6 h-6 bg-white rounded-full shadow -left-1 -top-1 transition"></div>
            </div>
          </label>

          <label for="propertyName" class="flex items-center cursor-pointer w-full my-3">
            <div class="w-full mr-3">
              Property name
            </div>
            <div class="relative">
              <input v-model="widget.propertyName" id="propertyName" type="checkbox" class="sr-only" @change="handleChange()"/>
              <div class="w-10 h-4 bg-gray-200 rounded-full shadow-inner"></div>
              <div class="dot absolute w-6 h-6 bg-white rounded-full shadow -left-1 -top-1 transition"></div>
            </div>
          </label>

          <label v-if="selectedLayout !== 'collage'" for="postTitle" class="flex items-center cursor-pointer w-full my-3">
            <div class="w-full mr-3">
              Post title
            </div>
            <div class="relative">
              <input v-model="widget.postTitle" id="postTitle" type="checkbox" class="sr-only" @change="handleChange()"/>
              <div class="w-10 h-4 bg-gray-200 rounded-full shadow-inner"></div>
              <div class="dot absolute w-6 h-6 bg-white rounded-full shadow -left-1 -top-1 transition"></div>
            </div>
          </label>

          <label v-if="selectedLayout !== 'collage'" for="postCaption" class="flex items-center cursor-pointer w-full my-3">
            <div class="w-full mr-3">
              Post caption
            </div>
            <div class="relative">
              <input v-model="widget.postCaption" id="postCaption" type="checkbox" class="sr-only" @change="handleChange()"/>
              <div class="w-10 h-4 bg-gray-200 rounded-full shadow-inner"></div>
              <div class="dot absolute w-6 h-6 bg-white rounded-full shadow -left-1 -top-1 transition"></div>
            </div>
          </label>

          <h1 class="mt-8 text-lg font-semibold">Properties</h1>

          <label class="check" v-for="property in properties" :key="property.$id">
            <input v-model="selectedProperties" type="checkbox" :value="property.$id" @change="handleChange()">
            <span class="checkmark"></span>
            {{property.name}}
          </label>

          <!--
          <h1 class="mt-8 text-lg font-semibold">Brands</h1>
          <h1 class="mt-8 text-lg font-semibold">Type</h1>
          <h1 class="mt-8 text-lg font-semibold">Language</h1>
          only video only
          -->

        </div>
      </div>
      <div class="flex-shrink flex-grow p-8">

        <div class="mx-auto xl:max-w-screen-xl border border=gray-300 rounded-lg shadow-xl overflow-hidden mb-4">
          <div class="p-2.5 pb-2 bg-gray-200">
            <div class="flex">
              <div class="flex-none w-20">
                <span class="inline-block rounded-full w-3 h-3 ml-2" style="background-color: #FF5F57"></span>
                <span class="inline-block rounded-full w-3 h-3 ml-2" style="background-color: #FEBC2D"></span>
                <span class="inline-block rounded-full w-3 h-3 ml-2" style="background-color: #28C840"></span>
              </div>
              <div class="grow w-full text-center text-sm text-black">
                {{widget.name}} &ndash; Widget Preview
              </div>
              <div class="flex-none w-20"></div>
            </div>
          </div>
          <div class="border bg-gray-100" :class="widget.layout === 'centered-slider' ? 'py-8' : 'p-8'">
            <embedded :widget="widget"></embedded>
          </div>
        </div>

      </div>
    </div>
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'
import Embedded from '../components/Embedded.vue'

export default {
  name: 'Posts',
  components: {
    Embedded
  },
  data () {
    return {
      mounted: false,
      changes: false,
      modal: false,
      selectedLayout: null,
      selectedProperties: [],
      postBorderRadius: 'rounded-md',
      postBorderWidth: 'border-0',
      postShadow: 'shadow-xl',
      borderRadius: [
        { label: 'None', value: 'rounded-none' },
        { label: 'Small', value: 'rounded-sm' },
        { label: 'Regular', value: 'rounded' },
        { label: 'Medium', value: 'rounded-md' },
        { label: 'Large', value: 'rounded-lg' },
        { label: 'Extra Large', value: 'rounded-xl' },
        { label: '2x Extra Large', value: 'rounded-2xl' },
        { label: '3x Extra Large', value: 'rounded-3xl' }
      ],
      borderWidth: [
        { label: 'None', value: 'border-0' },
        { label: '1 pixel', value: 'border' },
        { label: '2 pixels', value: 'border-2' },
        { label: '4 pixels', value: 'border-4' }
      ],
      shadow: [
        { label: 'None', value: 'shadow-none' },
        { label: 'Small', value: 'shadow-sm' },
        { label: 'Regular', value: 'shadow' },
        { label: 'Medium', value: 'shadow-md' },
        { label: 'Large', value: 'shadow-lg' },
        { label: 'Extra Large', value: 'shadow-xl' },
        { label: 'XXL', value: 'shadow-2xl' }
      ],
      selectedLanguage: '',
      language: [
        { label: 'English', value: 'en' },
        { label: 'French', value: 'fr' }
      ],
      selectedType: '',
      type: [
        { label: 'Special Offer', value: 'offer' },
        { label: 'News', value: 'news' },
        { label: 'Guest Promotion', value: 'guest' },
        { label: 'Event', value: 'event' }
      ]
    }
  },
  async mounted () {
    this.fetchWidget(this.$route.params.widget_id)

    const response = await this.fetchWidget(this.$route.params.widget_id)
    if (response) {
      setTimeout(function () {
        if (this.widget.postBorderRadius) {
          this.postBorderRadius = this.borderRadius.filter(opt => opt.value === this.widget.postBorderRadius)[0]
        } else {
          this.postBorderRadius = this.borderRadius.filter(opt => opt.value === this.postBorderRadius)[0]
          this.widget.postBorderRadius = this.postBorderRadius.value
        }

        if (this.widget.postBorderWidth) {
          this.postBorderWidth = this.borderWidth.filter(opt => opt.value === this.widget.postBorderWidth)[0]
        } else {
          this.postBorderWidth = this.borderWidth.filter(opt => opt.value === this.postBorderWidth)[0]
          this.widget.postBorderWidth = this.postBorderWidth.value
        }

        if (this.widget.postShadow) {
          this.postShadow = this.shadow.filter(opt => opt.value === this.widget.postShadow)[0]
        } else {
          this.postShadow = this.shadow.filter(opt => opt.value === this.postShadow)[0]
          this.widget.postShadow = this.postShadow.value
        }
        this.changes = false
      }.bind(this), 500)

      this.selectedProperties = this.widget.properties || []
      this.selectedLayout = this.widget.layout
    }
  },
  methods: {
    ...mapActions(['fetchWidget', 'updateWidget']),
    handleChange () {
      this.changes = true
    },
    handleChangeLayout (layout) {
      this.widget.layout = null
      setTimeout(function () { this.widget.layout = layout }.bind(this), 10)
      this.handleChange()
      return false
    },
    async handleUpdate (widget) {
      const data = {
        name: widget.name,
        layout: widget.layout,
        propertyLogo: widget.propertyLogo,
        propertyName: widget.propertyName,
        postTitle: widget.postTitle,
        postCaption: widget.postCaption,
        postBorderRadius: widget.postBorderRadius,
        postBorderWidth: widget.postBorderWidth,
        postBorderColor: widget.postBorderColor,
        postShadow: widget.postShadow,
        properties: widget.properties
      }

      const payload = {
        data: data,
        documentId: widget.$id,
        read: widget.$read,
        write: widget.$write
      }

      const response = await this.updateWidget(payload)

      if (response) {
        this.changes = false
      }
    }
  },
  watch: {
    postBorderRadius () {
      this.widget.postBorderRadius = this.postBorderRadius.value
      this.changes = true
    },
    postBorderWidth () {
      this.widget.postBorderWidth = this.postBorderWidth.value
      this.changes = true
    },
    postBorderColor () {
      this.widget.postBorderColor = this.postBorderColor.value
      this.changes = true
    },
    postShadow () {
      this.widget.postShadow = this.postShadow.value
      this.changes = true
    },
    selectedProperties () {
      this.widget.properties = this.selectedProperties
    },
    selectedLanguage () {
      if (this.selectedLanguage) {
        this.widget.language = this.selectedLanguage.value
      } else {
        this.widget.language = null
      }
    },
    selectedType () {
      if (this.selectedType) {
        this.widget.type = this.selectedType.value
      } else {
        this.widget.type = null
      }
    }
  },
  computed: {
    ...mapGetters(['widget', 'properties'])
  }
}
</script>

<style>
input:checked ~ .dot {
  transform: translateX(100%);
  background-color: #4B5563;
}
.radio {
  display: block;
  margin: .75rem 0;
}
.radio input[type=radio] {
  position: absolute;
  opacity: 0;
}
.radio input[type=radio] + .radio-label:before {
  content: "";
  background: #f4f4f4;
  border-radius: 100%;
  border: 1px solid #DADCDF;
  display: inline-block;
  width: 1.2em;
  height: 1.25em;
  position: relative;
  top: 0.1em;
  margin-right: 1em;
  vertical-align: top;
  cursor: pointer;
  text-align: center;
  transition: all 250ms ease;
}
.radio input[type=radio]:checked + .radio-label:before {
  background-color: #4B5563;
  box-shadow: inset 0 0 0 4px #f4f4f4;
}
.radio input[type=radio]:focus + .radio-label:before {
  outline: none;
  border-color: #D1D5DB;
}
.radio input[type=radio]:disabled + .radio-label:before {
  box-shadow: inset 0 0 0 4px #f4f4f4;
  border-color: #b4b4b4;
  background: #b4b4b4;
}
.radio input[type=radio] + .radio-label:empty:before {
  margin-right: 0;
}

/* Customize the label (the container) */
.check {
  display: block;
  position: relative;
  padding-left: 2rem;
  margin: 0.75rem 0;
  cursor: pointer;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

/* Hide the browser's default checkbox */
input[type=checkbox] {
  position: absolute;
  opacity: 0;
  cursor: pointer;
  height: 0;
  width: 0;
}

/* Create a custom checkbox */
.checkmark {
  position: absolute;
  top: 0;
  left: 0;
  height: 1.25rem;
  width: 1.25rem;
  background-color: #F4F4F4;
  border: 1px solid #DADCDF;
  border-radius: 3px;
}

/* Create the checkmark/indicator (hidden when not checked) */
.checkmark:after {
  content: "";
  position: absolute;
  display: none;
}

/* Show the checkmark when checked */
.check input:checked ~ .checkmark:after {
  display: block;
}

/* Style the checkmark/indicator */
.checkmark:after {
  left: 7px;
  top: 3px;
  width: 5px;
  height: 10px;
  border: solid #4B5563;
  border-width: 0 3px 3px 0;
  -webkit-transform: rotate(45deg);
  -ms-transform: rotate(45deg);
  transform: rotate(45deg);
}
</style>
