<template>
<div>
  <div v-if="widget.layout === 'collage'">
    <!-- Collage -->
    <div class="collage grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 xl:grid-cols-4 gap-4 lg:gap-8">
      <div v-for="post in posts.slice(0, 15)" :key="post.$id" class="bg-white overflow-hidden transition duration-200 ease-in-out transform" :class="widget.postBorderRadius + ' ' + widget.postBorderWidth + ' ' + widget.postShadow" :style="widget.postBorderColor ? 'border-color: ' + widget.postBorderColor : ''">
        <post :post="post" :widget="widget" format="slider"/>
      </div>
    </div>
  </div>
  <div v-else-if="widget.layout === 'slider'">
    <!-- Slider -->
    <div class="-mx-4 overflow-hidden">
      <hooper ref="slider" :settings="hooperSettings">
        <slide v-for="post in posts.slice(0, 15)" :key="post.$id">
          <div class="bg-white overflow-hidden transition duration-200 ease-in-out transform mx-4" :class="widget.postBorderRadius + ' ' + widget.postBorderWidth + ' ' + widget.postShadow" :style="widget.postBorderColor ? 'border-color: ' + widget.postBorderColor : ''">
            <post :post="post" :widget="widget" format="slider"/>
          </div>
        </slide>
        <hooper-navigation slot="hooper-addons"></hooper-navigation>
      </hooper>
    </div>
  </div>
  <div v-else-if="widget.layout === 'centered-slider'" class="centered-slider">
    <!-- Centered Slider -->
    <hooper :settings="hooperSettingsCenter">
      <slide v-for="post in posts.slice(0, 15)" :key="post.$id">
        <div class="bg-white overflow-hidden transition duration-200 ease-in-out transform mx-4" :class="widget.postBorderRadius + ' ' + widget.postBorderWidth + ' ' + widget.postShadow" :style="widget.postBorderColor ? 'border-color: ' + widget.postBorderColor : ''">
          <post :post="post" :widget="widget" format="slider"/>
        </div>
      </slide>
      <hooper-navigation slot="hooper-addons"></hooper-navigation>
    </hooper>
  </div>
  <div v-else-if="widget.layout === 'gallery'">
    <!-- Gallery -->
    <div class="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4 gap-4 lg:gap-8">
      <div v-for="post in posts.slice(0, 16)" :key="post.$id" class="bg-white overflow-hidden transition duration-200 ease-in-out transform" :class="widget.postBorderRadius + ' ' + widget.postBorderWidth + ' ' + widget.postShadow" :style="widget.postBorderColor ? 'border-color: ' + widget.postBorderColor : ''">
        <post :post="post" :widget="widget" format="grid"/>
      </div>
    </div>
  </div>
  <div v-else-if="widget.layout === 'masonry'">
    <!-- Masonry -->
    <div class="md:masonry-2-col lg:masonry-3-col xl:masonry-4-col box-border mx-auto before:box-inherit after:box-inherit">
      <div v-for="post in posts" :key="post.$id" class="break-inside bg-white overflow-hidden transition duration-200 ease-in-out transform" :class="widget.postBorderRadius + ' ' + widget.postBorderWidth + ' ' + widget.postShadow" :style="widget.postBorderColor ? 'border-color: ' + widget.postBorderColor : ''">
        <post :post="post" :widget="widget" format="masonry"/>
      </div>
    </div>
  </div>

</div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'
import { Query } from 'appwrite'
import Post from '../components/Post.vue'
import { Hooper, Slide, Navigation as HooperNavigation } from 'hooper'
import 'hooper/dist/hooper.css'

export default {
  name: 'Embedded',
  components: {
    Post,
    Hooper,
    Slide,
    HooperNavigation
  },
  props: ['widget'],
  data () {
    return {
      hooperSettings: {
        itemsToShow: 1,
        centerMode: true,
        wheelControl: false,
        breakpoints: {
          640: {
            centerMode: false,
            itemsToShow: 2,
            infiniteScroll: true,
            wheelControl: false
          },
          768: {
            centerMode: false,
            itemsToShow: 3,
            infiniteScroll: true,
            wheelControl: false
          },
          1024: {
            centerMode: false,
            itemsToShow: 4,
            itemsToSlide: 3,
            infiniteScroll: true,
            wheelControl: false
          }
        }
      },
      hooperSettingsCenter: {
        itemsToShow: 1.5,
        centerMode: true,
        wheelControl: false,
        breakpoints: {
          640: {
            centerMode: true,
            itemsToShow: 1.75,
            infiniteScroll: true,
            wheelControl: false
          },
          768: {
            centerMode: true,
            itemsToShow: 2.25,
            itemsToSlide: 2,
            infiniteScroll: true,
            wheelControl: false
          },
          1024: {
            centerMode: true,
            itemsToShow: 3.5,
            itemsToSlide: 3,
            infiniteScroll: true,
            wheelControl: false
          }
        }
      }
    }
  },
  watch: {
    widget: {
      handler (properties) {
        const queries = []
        if (this.widget.type) {
          queries.push(Query.equal('type', this.widget.type))
        } else if (this.widget.language) {
          queries.push(Query.equal('language', this.widget.language))
        } else {
          console.log(this.widget.properties)

          // fix 128 character filter limitation by searching for propertyIds starting with
          var truncated = []
          var arrayLength = this.widget.properties.length
          for (var i = 0; i < arrayLength; i++) {
            truncated.push(this.widget.properties[i].substring(0, 6))
          }

          queries.push(Query.search('propertyId', truncated))
        }
        this.fetchPosts({
          queries: queries
        })
      },
      deep: true
    }
  },
  mounted () {

  },
  methods: {
    ...mapActions(['fetchPosts'])
  },
  computed: {
    ...mapGetters(['posts'])
  }
}
</script>

<style>
.hooper {
  height:auto !important;
}

.hooper-slide {
  height:auto !important;
}

.hooper-slide > div {
  height: 100%;
}

.hooper-next,
.hooper-prev {
  background-color: white;
  border-radius: 9999px;
  width: 30px;
  height: 30px;
  padding: 0 3px;
  opacity: .75;
}
.hooper-next:hover,
.hooper-prev:hover {
  opacity: 1;
}
.hooper-next {
  right: 30px;
}
  .centered-slider .hooper-next {
    right: 20px;
  }
.hooper-prev {
  left: 30px;
}
  .centered-slider .hooper-prev {
    right: 20px;
  }
</style>
